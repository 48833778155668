import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
import styles from "./reportsModule.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import { FaEye, FaArrowRotateRight, FaBan } from "react-icons/fa6";
import {
  notifySuccess,
  notifyError,
} from "../../apiConnection/notification.api";
import ViewReport from "./ViewReport";
import { customStyles } from "../../helpers/commonStyles";
import {
  reportsListing,
  banOrganization,
  recoverOrganization,
} from "../../environment";
import Form from "react-bootstrap/Form";
import SearchMain from "../../components/MainSearch";
const UserReport = () => {
  const [users, setUsers] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [reportDetail, setReportDetail] = useState("");
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState(null);
  const getReportsListing = async () => {
    const reports = await axios.get(reportsListing, {
      params: {
        role: "user",
        filter,
        ...(search && { search }),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (reports?.data) {
      setUsers(reports?.data?.data);
    }
  };
  const handleRecoverOrganization = async (id, actionType) => {
    try {
      let response;
      if (actionType === "ban") {
        response = await axios.post(
          `${banOrganization}`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.post(
          `${recoverOrganization}`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }

      if (response?.data) {
        notifySuccess(response?.data?.message);
        getReportsListing();
      }
    } catch (error) {
      console.log("error :", error);
      notifyError(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getReportsListing();
  }, [filter]);
  useEffect(() => {
    let timerId;
    if (search?.length > 3 || !search) {
      // Clear any existing timer
      clearTimeout(timerId);

      // Set a new timer to trigger getReportsListing after 1 second
      timerId = setTimeout(() => {
        getReportsListing();
      }, 500);
    }

    // Cleanup function to clear the timer if component unmounts or search changes
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  const columns = [
    {
      name: "Reporter Name",
      selector: (row) =>
        row?.reportedByUser?.firstName + " " + row?.reportedByUser?.lastName,
    },
    {
      name: "Reported User",
      selector: (row) =>
        row?.reportedToUser?.firstName + " " + row?.reportedToUser?.lastName,
    },
    {
      name: "Reported Profile Url",
      selector: (row) => (
        <a
          href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/user/${row?.reportedToUser?.username}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${process.env.REACT_APP_FRONTEND_BASE_URL}/user/${row?.reportedToUser?.username}`}
        </a>
      ),
    },
    {
      name: "Number of Reports",
      selector: (row) => row?.reports?.length,
      sortable: true,
    },
    {
      name: "User Reporters",
      selector: (row) =>
        row?.reports
          ?.map((report) => report?.reportedByUser?.firstName)
          .join(", "),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className={styles.viewButton}
            onClick={() => {
              setDeleteId(row?._id);
              setReportDetail(row);
              setShow(true);
            }}
          >
            <FaEye /> View
          </button>
          {row?.reports[0]?.isBan === true ? (
            <button
              className={styles.recoverButton}
              onClick={() => {
                handleRecoverOrganization(row?.reports[0]?._id, "recover");
              }}
            >
              <FaArrowRotateRight /> Reinstate
            </button>
          ) : (
            <button
              className={styles.banButton}
              onClick={() => {
                handleRecoverOrganization(row?.reports[0]?._id, "ban");
              }}
            >
              <FaBan /> Ban
            </button>
          )}
        </>
      ),
    },
  ];

  const data = users;
  console.log("🚀 ~ UserReport ~ data:", data);

  return (
    <>
      <div className={styles.settings}>
        <div
          className={`${styles.btnOuter} d-flex align-items-center justify-content-between`}
        >
          <h3>Users Reports</h3>

          <div className="formOuter">
            <Form.Select onChange={(e) => setFilter(e.target.value)}>
              <option value="all">Show all users</option>
              <option value="banned">Ban user</option>
              <option value="unbanned">Unban user</option>
            </Form.Select>
          </div>
        </div>
        <SearchMain onChange={(e) => setSearch(e.target.value)} />

        <DataTable
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </div>
      <ViewReport
        show={show}
        id={deleteId}
        setShow={setShow}
        getReportsListing={getReportsListing}
        reportDetail={reportDetail}
      />

      <TooltipGlodal />
    </>
  );
};

export default UserReport;
