import { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Select, DatePicker} from "antd";
import styles from "../NftSold/NFTSold.module.sass";
import axios from "axios";
import { analyticsNFTStatsByType } from "../../../environment";
import moment from "moment";
const { RangePicker } = DatePicker;

function MaticValue() {
  const [dateFilter, setDateFilter] = useState("all");
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const options = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Last 24 hours", value: "last24Hours" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },

  ];
  for (let i = 4; i < 4; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const getStats = async () => {
    const {startDate,endDate}=dateRange
    const stats = await axios.get(analyticsNFTStatsByType, {
      params: {
        dateFilter,
        graphType: 2,
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    let count = 0;
    const res = stats.data.data.map((item) => {
      const createdAt = moment(item.date);
      count = count + item?.count;
      return {
        name:
          dateFilter === "today"
            ? createdAt.format("HH:mm")
            : createdAt.format("D MMMM"),
        Total_Matics: count,
        pv: count,
      };
    });
    setData(res);
  };
  useEffect(() => {
    getStats();
  }, [dateFilter,dateRange]);

  const handleChange = (value) => {
    setDateFilter(value);
  };
  const handleRangePicker = (dates) => {
    setDateFilter("all");
    setDateRange({
      startDate: dates ? dates[0]?.toISOString() : null,
      endDate: dates ? dates[1]?.toISOString() : null,
    });
  };
  function disabledDate(current) {
    // Can not select days after today
    return current && current > moment().endOf("day");
  }

  return (
    <div className={styles.analyticscard}>
      <h3>Total Matic Value of NFTs sold</h3>
      <div className={styles.selects}>
        <Select
          //   mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select by Particular date"
          defaultValue={["All"]}
          onChange={handleChange}
          options={options}
          disabled={dateRange?.startDate}
          value={dateFilter}
        />
         <RangePicker
          style={{
            width: "100%",
          }}
          disabledDate={disabledDate}
          onChange={handleRangePicker}
        />
      </div>
      {data && (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#3772FF"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default MaticValue;
